import * as React from "react";

import ButtonElement from "../components/shared/button-element";
import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Layout from "../components/shared/layout";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";
import { WrappingH5 } from "../components/shared/headings";

const SignInWithApple = () => {
  React.useEffect(() => {
    // Build deep link with received params
    const urlParams = new URLSearchParams(window.location.search);
    const apiKey = urlParams.get("apiKey");
    const oobCode = urlParams.get("oobCode");
    const mode = urlParams.get("mode");
    const environmentParam = urlParams.get("env") || "production";
    const environment = environmentParam.match(/^[\w-]+$/)
      ? environmentParam
      : "production";
    const a = document.getElementById("completeVerification");
    a.href = `com.copilot.${environment}://auth?apiKey=${apiKey}&oobCode=${oobCode}&mode=${mode}`;
  });

  return (
    <Layout>
      <Seo title={"Email link | Copilot"} />
      <DownloadModal />
      <Navbar />
      <Section>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: "0 20px",
          }}
        >
          <WrappingH5>
            To complete the email verification process, tap on the button below
            on the same device where you're using Copilot.
          </WrappingH5>
          <ButtonElement
            id="completeVerification"
            outbound
            href=""
            style={{ backgroundColor: "white", marginTop: "24px" }}
          >
            Complete the verification
          </ButtonElement>
        </div>
      </Section>
      <Footer />
    </Layout>
  );
};

export default SignInWithApple;
